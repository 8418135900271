document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.review-card').forEach((card) => {
    const textElement = card.querySelector('.review-card__header span');
    const button = card.querySelector('.review-card__opener-btn');

    if (!textElement || !button) return;

    // Проверяем количество строк
    const lineHeight = parseInt(
      window.getComputedStyle(textElement).lineHeight
    );
    const maxHeight = lineHeight * 3; // Высота 3 строк
    textElement.style.maxHeight = `${maxHeight}px`;
    textElement.style.overflow = 'hidden';
    textElement.style.display = '-webkit-box';
    textElement.style.webkitBoxOrient = 'vertical';
    textElement.style.webkitLineClamp = 3;

    // Проверяем, хватает ли текста для показа кнопки
    if (textElement.scrollHeight <= maxHeight) {
      button.style.display = 'none'; // Скрываем кнопку, если текста меньше 3 строк
    }

    // Добавляем обработчик на кнопку
    button.addEventListener('click', function () {
      if (textElement.style.webkitLineClamp === '3') {
        textElement.style.webkitLineClamp = 'unset'; // Показываем весь текст
        textElement.style.maxHeight = 'none';
        button.textContent = 'Скрыть';
      } else {
        textElement.style.webkitLineClamp = '3'; // Возвращаем 3 строки
        textElement.style.maxHeight = `${maxHeight}px`;
        button.textContent = 'Весь отзыв';
      }
    });
  });
});
